<template>
  <div>
    <div class="bigDiv" v-if="zf">
      <img
        src="https://oss.hshwhkj.com/hshcrm/image/2024/09/27/172743237785673755.png"
        alt=""
        style="margin-bottom: 120px;"
        class="img"
      />

      <div class="btoDiv" @click="throttleSubmit">
        <div class="text">
          限时仅需:￥1
        </div>
        <div class="right">
          <div class="A">前200名限时秒抢</div>
          <div class="B">马上报名</div>
        </div>
      </div>

      <div class="chuang" v-if="tcFlag === 1">
        <img
          class="titImg"
          src="https://oss.hshwhkj.com/hshcrm/image/2024/08/29/17249214417744503.jpg"
          alt=""
        />
        <div class="name">徐千佳老师</div>
        <div class="text">给你发了一个学习红包</div>
        <img
          src="https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897272271175784.png"
          alt=""
          class="kai"
          :class="{ rotating: flag }"
          @click="aaa"
        />
      </div>

      <div class="chuang2" v-if="tcFlag === 2">
        <img
          src="https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897376658700216.png"
          alt=""
          class="qian"
        />
        <div class="menny">
          100元
        </div>
        <div class="text">经络瑜伽训练营</div>
        <div class="text2">
          距离过期还有:
          <van-count-down :time="time">
            <template #default="timeData">
              <div class="daojis">
                <span>{{ timeData.minutes }}</span>
                <span>分</span>
                <span>{{ timeData.seconds }}</span>
                <span>秒</span>
              </div>
            </template>
          </van-count-down>
        </div>
        <img
          class="button"
          src="https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897473546034690.png"
          alt=""
          @click="bbbb"
        />
      </div>
      <div class="model" v-if="tcFlag !== 4"></div>

      <!--  -->
      <van-action-sheet v-model="show" @close="onclose">
        <div class="content">恭喜你获得最大折扣!</div>
        <div class="info1">
          <div class="a1">课程价:</div>
          <div class="a2">￥109.90</div>
        </div>
        <div class="xian"></div>
        <div class="info1">
          <div class="a1">学习红包:</div>
          <div class="a2">-￥100.90</div>
        </div>
        <div class="xian"></div>
        <div class="info1">
          <div class="a3">特惠价</div>
          <div class="a4">9.90</div>
        </div>
        <div class="btoDiv" @click="throttleSubmit">
          <div class="text">
            限时仅需:￥1
          </div>
          <div class="right">
            <div class="A">前200名限时秒抢</div>
            <div class="B">马上报名</div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div v-else class="wanliuDIv">
      <div class="topDiv">
        <div class="zhifus">剩余支付时间</div>
        <div class="zhifut">
          <van-count-down millisecond :time="time" format="mm:ss:SS" />
        </div>
        <div class="imgs" @click="throttleSubmit">
          <img
            src="https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897840359497166.png"
            alt=""
          />
        </div>
      </div>
      <div class="bodyDiv">
        <div class="top"></div>
        <div class="bottom">
          <div class="scroll-container" ref="scrollContainer">
            <!-- 原始内容 -->
            <div class="info" v-for="(item, index) in items" :key="index">
              <img class="topUrl" :src="item.image" alt="" />
              <div class="text">
                <div class="a">{{ item.name }}</div>
                <div class="b">{{ item.description }}</div>
              </div>
            </div>

            <!-- 复制的内容，和原始内容相同 -->

            <div v-for="item in 50" :key="item">
              <div
                class="info"
                v-for="(item, index) in items"
                :key="'copy-' + index"
              >
                <img class="topUrl" :src="item.image" alt="" />
                <div class="text">
                  <div class="a">{{ item.name }}</div>
                  <div class="b">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btoDiv" @click="throttleSubmit">
        <div class="text">
          限时仅需:￥1
        </div>
        <div class="right">
          <div class="A">前200名限时秒抢</div>
          <div class="B">马上报名</div>
        </div>
      </div>
      <!-- <div class="toast">
        <div
          v-if="shows"
          class="animate__animated animate__slideOutUp  "
          :key="currentIndex"
        >
          <div class="texts">{{ currentItem }}领取了课程</div>
        </div>
      </div> -->
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          :speed="10"
        >
          <van-swipe-item v-for="item in times" :key="item.name">{{
            item.name
          }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>
</template>
<script>
import { getTgUnionid, getTgUnifyPayH5 } from "../../api/components";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      ruleForm: {
        land_link: "", //落地页链接
      },
      memberInfo: {},
      flag: false,
      tcFlag: 1,
      shows: true,
      show: false,
      time: 5 * 60 * 1000,
      zf: true,
      currentIndex: 0, // 当前显示的项的索引
      currentimg: "", //
      currentItem: "", // 当前显示的项
      items: [
        {
          name: "岁月静好",
          description: "第一次练,身体舒爽",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
        },
        {
          name: "兰姐",
          description: "效果显著，值得推荐",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172898379542529440.png",
        },
        {
          name: "家珍妈妈",
          description: "感到全身轻松",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172898401651414969.png",
        },
        {
          name: "平平淡淡才是真",
          description: "训练后身体明显改善",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172898411213215139.png",
        },
      ],
      times: [
        {
          name: "高康妈妈8秒前报名了课程",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
        },
        {
          name: "树姐13秒前报名了课程",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
        },
        {
          name: "徐女士5秒前报名了课程",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
        },
        {
          name: "佳洁士3秒前报名了课程",
          image:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
        },
      ],
    };
  },
  created() {
    const code = this.$route.query.code;
    this.ruleForm.land_link = window.location.href;
    if (sessionStorage.getItem("isShow")) {
      this.tcFlag = 4;
    }
    if (code) {
      this.getTgUnionid();
      this.tcFlag = 4;
    }
  },
  methods: {
    onclose() {
      this.tcFlag = 4;
    },
    aaa() {
      this.flag = !this.flag;
      setTimeout(() => {
        this.tcFlag = 2;
      }, 1500);
    },
    bbbb() {
      this.tcFlag = 3;
      this.show = true;
    },
    throttleSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick();
    },
    async wxClick() {
      const codes = sessionStorage.getItem("queryCode");
      if (codes) {
        return this.getTgUnifyPayH5();
      }

      if (this.is_weixn()) {
        this.getCode();
      } else {
        this.getTgUnifyPayH5();
      }
    },

    is_weixn() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode() {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手動授權
      let info = {
        appid: "wx143b46f871cc09d6",
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      const { code, mobile, mobilecode, ...rest } = this.$route.query;

      // 将剩余字段转换为查询字符串
      const queryString = new URLSearchParams(rest).toString();

      console.log(123, queryString);
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/test?${queryString}`
        )}&response_type=code&scope=${scope}#wechat_redirect`
      );
      // }
    },
    async getTgUnionid() {
      sessionStorage.setItem("isShow", 4);
      const codes = sessionStorage.getItem("queryCode");
      if (codes) {
        return this.getTgUnifyPayH5();
      }
      try {
        const query = {
          code: this.$route.query.code,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
        };
        const { data } = await getTgUnionid(query);
        this.memberInfo = data.data;
        console.log(data.data);
        console.log(this.memberInfo, "88");
        this.$toast.loading("正在加载,请稍等~");
        sessionStorage.setItem("queryCode", this.$route.query.code);
        this.getTgUnifyPayH5();
      } catch (error) {
        console.log(error);
      }
    },
    async getTgUnifyPayH5() {
      try {
        const query = {
          appid: "wx143b46f871cc09d6",
          open_id: this.memberInfo.openid,
          price: this.$route.query.price || 0,
          unionid: this.memberInfo.unionid,
          headimgurl: this.memberInfo.headimgurl,
          wechat_name: this.memberInfo.nickname,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
          question: this.question,
        };
        const res = await getTgUnifyPayH5(query);
        console.log(res, "8897879");
        this.wechatPay(res);
      } catch (error) {
        console.log(error, "我是错误");
        // this.getCode()
      }
    },

    wechatPay(res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no,
        } = res.data.data.data;

        wx.config({
          debug: false,
          appId: "wx143b46f871cc09d6",
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"],
        });
        this.$toast.clear();

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log("成功");
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付成功后的回调函数
            this.$router.replace({
              path: "/txTgPayOk",
              query: {
                trade_no: trade_no,
              },
            });
          },

          cancel: (res) => {
            this.zf = false;
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付失败后的回调函数
          },
        });
      } else {
        console.log(res.data.data.data);
        window.location.href = res.data.data.data;
      }
    },
  },

  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 100%;
  .img {
    width: 100%;
    object-fit: contain;
  }
  .btoDiv {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: #cd3623;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
    }
    .right {
      width: 160px;
      background-color: #fff;
      height: 55px;
      line-height: 55px;
      border-radius: 50px;
      color: #cd3724;
      animation: clickPay 0.8s infinite;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .A {
        font-size: 12px;
        width: 100%;
        height: 12px;
        margin-bottom: 5px;
      }
      .B {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
  @keyframes clickPay {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  .chuang {
    width: 320px;
    z-index: 10;

    height: 470px;
    background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/10/15/1728972494912590.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 2s ease-in-out;
    .titImg {
      width: 80px;
      height: 80px;
      object-fit: contain;
      border-radius: 50px;
      position: fixed;
      top: 10%;
      left: 50%;
      transform: translate(-50%);
    }
    .name {
      width: 100%;
      text-align: center;
      position: fixed;
      top: 30%;
      left: 50%;
      transform: translate(-50%);
      color: #ffd789;
      font-size: 16px;
    }
    .text {
      width: 100%;
      text-align: center;
      position: fixed;
      top: 40%;
      left: 50%;
      transform: translate(-50%);
      color: #ffd789;
      font-size: 18px;
    }
    .kai {
      width: 100px;
      height: 100px;
      object-fit: contain;
      position: fixed;
      left: 50%;
      top: 79%;
      transform: translate(-50%, -50%); /* 水平和垂直居中 */
      transition: transform 0.3s ease; /* 可以加个过渡 */
    }

    /* 定义旋转动画 */
    @keyframes rotateAnimation {
      from {
        transform: translate(-50%, -50%) rotateY(0deg); /* 将位移和旋转合并 */
      }
      to {
        transform: translate(-50%, -50%) rotateY(360deg); /* 旋转一圈 */
      }
    }

    /* 动画类 */
    .rotating {
      animation: rotateAnimation 2s linear infinite; /* 无限循环旋转 */
    }
  }
  @keyframes rotateAnimation {
    from {
      transform: translate(-50%, -50%) rotateY(0deg); /* 将位移和旋转合并 */
    }
    to {
      transform: translate(-50%, -50%) rotateY(360deg); /* 旋转一圈 */
    }
  }
  .chuang2 {
    z-index: 10;
    background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897463924420834.png");
    width: 360px;
    height: 470px;
    background-size: contain;
    background-repeat: no-repeat;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .qian {
      width: 360px;
      height: 470px;
      object-fit: contain;
      position: relative;
      left: -10px;
      animation: clickPay 1.8s infinite;
    }
    .hb {
      position: fixed;
      width: 100%;
      text-align: center;
      top: 8%;
      color: #fc5151;
      font-size: 16px;
    }
    .menny {
      position: fixed;
      width: 100%;
      text-align: center;
      top: 22%;
      color: #f64a4a;
      font-size: 64px;
      font-weight: 700;
    }
    .text {
      position: fixed;
      width: 100%;
      text-align: center;
      top: 55%;
      color: #ffd480;
      font-size: 20px;
      font-weight: 700;
    }
    .text2 {
      position: fixed;
      width: 100%;
      text-align: center;
      top: 63%;
      color: #ffd480;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      .daojis {
        color: #ffd480;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        top: 1px;
        left: 10px;
      }
    }

    .button {
      width: 160px;
      height: 55px;
      object-fit: contain;
      position: fixed;
      left: 50%;
      top: 73%;
      transform: translate(-50%);
    }
  }
  .model {
    width: 100%;
    height: 1800px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
  }
  .content {
    width: 100%;
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 700;
    color: #fd3131;
  }
  .info1 {
    display: flex;
    width: 300px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    .a1 {
      color: #999;
    }
    .a3 {
      color: #000;
      font-weight: 700;
      font-size: 22px;
    }
    .a4 {
      color: #fd3131;
      font-weight: 700;
      font-size: 22px;
    }
  }
  .xian {
    width: 300px;
    margin: 15px auto;
    height: 1px;
    background-color: #ccc;
  }
}
.wanliuDIv {
  width: 100%;
  overflow: hidden;
  .topDiv {
    width: 340px;
    height: 240px;
    background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897794466353563.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px auto;
    overflow: hidden;
    .zhifus {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-top: 20px;
    }
    .zhifut {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin-top: 30px;
      .van-count-down {
        font-size: 30px;
        color: #fe3131;
      }
    }
    .imgs {
      width: 220px;
      height: 50px;
      margin: 20px auto;
      margin-top: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .bodyDiv {
    width: 340px;
    margin: 0 auto;

    .top {
      width: 340px;
      height: 50px;
      background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/10/15/172897866865639976.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto;
    }

    .scroll-container {
      display: flex;
      flex-direction: column;
      animation: scroll 300s linear infinite; /* 无限循环旋转 */
    }
    @keyframes scroll {
      0% {
        transform: translateY(0); /* 从顶部开始 */
      }
      100% {
        transform: translateY(-100%); /* 滚动到底部 */
      }
    }
    .bottom {
      width: 340px;
      margin: 0 auto;
      height: 200px;
      overflow: hidden;
      .info {
        width: 340px;
        min-height: 60px;
        padding: 10px 0;
        margin: 5px auto;
        border-top: #facfd1 1px dashed;
        display: flex;
        align-items: center;
        .topUrl {
          width: 40px;
          height: 40px;
          object-fit: contain;
          border-radius: 50px;
          margin: 0 10px;
        }
        .text {
          .a {
            font-weight: 700;
            font-size: 18px;
          }
          .b {
            font-size: 16px;
            width: 220px;
          }
        }
      }
    }
    margin-bottom: 80px;
  }
  .btoDiv {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    height: 80px;
    position: fixed;
    bottom: 0;
    background-color: #cd3623;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
    }
    .right {
      width: 160px;
      background-color: #fff;
      height: 55px;
      line-height: 55px;
      border-radius: 50px;
      color: #cd3724;
      animation: clickPay 0.8s infinite;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .A {
        font-size: 12px;
        width: 100%;
        height: 12px;
        margin-bottom: 5px;
      }
      .B {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
  .van-notice-bar {
    width: 189px;
    height: 30px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    bottom: 90px;
    right: 10px;
  }
  .van-icon {
    color: #fff !important;
  }

  .notice-swipe {
    height: 30px;
    line-height: 30px;
    color: #fff;
  }
}
</style>
<style>
.van-action-sheet__content {
  height: 340px;
}
.van-icon-volume-o:before {
  color: #fff !important;
  content: "\e73c";
}
</style>
